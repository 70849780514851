<template>
  <div class="page">
    <calc-page>
      <template slot="topBox">
        <annuity-year-editor ref="editor" :data="data"></annuity-year-editor>
      </template>

      <template slot="footerBtn">
        <div class="btn-box" v-if="!isUpdate">
          <div class="btn-cancel add_width" @click="onBack">取消</div>
          <div class="btn-confirm add_width" @click="onFinish">确定</div>
        </div>

        <div class="btn-box" v-else>
          <div class="btn-cancel update_width" @click="onBack">取消</div>
          <div class="btn-del update_width" @click="onDel">删除</div>
          <div class="btn-confirm update_width" @click="onUpdate">确定</div>
        </div>
      </template>
    </calc-page>
  </div>
</template>

<script>
import { Divider,Dialog } from "vant";
import CalcPage from "@/components/CalcPage";
import AnnuityYearEditor from "@/views/calc/annuity/components/AnnuityYearEditor.vue";

import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    CalcPage,
    AnnuityYearEditor,
    [Divider.name]: Divider,
  },
  data() {
    return {
      data: null,
      isUpdate: false,
    };
  },
  mounted() {
    const { item, isUpdate } = this.$route.query;
    if (isUpdate != undefined) {
      this.isUpdate = true;
      this.data = JSON.parse(item);
      console.log(this.data)
    }
  },
  computed: {
    dieDate: {
      get() {
        return this.getAnnuityReturnOnJobDie().dieDate;
      },
      set(val) {
        this.setAnnuityReturnOnJobDie({ dieDate: val });
      },
    },
    list: {
      get() {
        return this.getAnnuityReturnOnJobDie().feeList;
      },
      set(val) {
        this.setAnnuityReturnOnJobDie({ feeList: val });
      },
    },
    
  },
  methods: {
    onDel() {
      this.data = this.$refs.editor.getValue();
      const index = this.list.findIndex((item) => {
        if (item.idx === this.data.idx) {
          return true;
        }
      });

      this.list.splice(index, 1);
      this.list = this.list;

      this.$router.go(-1);
    },
    onUpdate() {
      this.data = this.$refs.editor.getValue();
      for (let i = 0; i < this.list.length; i++) {
        if (this.data.year == this.list[i].year && this.data.idx !== this.list[i].idx) {
           Dialog.alert({
            title: "缴费年度重合",
            message:
              "输入的缴费年度，和已经存在的缴费年度" +
              this.list[i].year +
              "重合, 请重新输入",
          });
          return;
        }
      }
      if (this.data.month > 12) {
        Dialog.alert({
          title: "缴费月数不正确",
          message: "输入的月数大于12"
        });
        return;
      }
      
      this.list.findIndex((item) => {
        if (item.idx === this.data.idx && this.dieDate.substring(0,4) >= this.data.year) {
          item.year = this.data.year;
          item.payment = this.data.payment;
          item.month = this.data.month;
          return true;
        }else {
          this.$toast.fail("缴费年度不能大于死亡年度，请重新修改");
        }
      });

      this.list = this.list;
      this.$router.go(-1);
    },
    onFinish() {
      this.data = this.$refs.editor.getValue();
      const { year, payment, month } = this.data;

      if (year == "") {
        this.$toast.fail("请填写缴费年度");
        return;
      }

      if (payment == "") {
        this.$toast.fail("请填写年缴费基数和");
        return;
      }

      if (month == "") {
        this.$toast.fail("请填写年缴费月数");
        return;
      }

      if (month > 12) {
        Dialog.alert({
          title: "缴费月数不正确",
          message: "输入的月数大于12"
        });
        return;
      }

      for (let i = 0; i < this.list.length; i++) {
        if (year == this.list[i].year) {
          Dialog.alert({
            title: "缴费年度重合",
            message:
              "输入的缴费年度，和已经存在的缴费年度" +
              this.list[i].year +
              "重合, 请重新输入",
          });
          return;
        }
      }

      if(this.dieDate.substring(0,4) >= this.data.year){
        this.list.push(this.data);
      }else {
        this.$toast.fail("缴费年度不能大于死亡年度，请重新输入");
      }
      // this.list.push(this.data);
      this.list = this.list;
      this.$router.go(-1);
    },
    onBack() {
      this.$router.go(-1);
    },
    ...mapGetters("calc", ["getAnnuityReturnOnJobDie"]),
    ...mapMutations("calc", ["setAnnuityReturnOnJobDie"]),
  },
};
</script>

<style scoped>
.page >>> .van-divider {
  margin: 0;
}

.item-box {
  /* display: flex;
  align-items: flex-end; */
  padding: 24px 0 12px 0;
}

.item-box >>> .van-cell {
  padding: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.row-box {
  display: flex;
  justify-content: space-between;
}

.row-box >>> .TitleItem__title--2DuKR {
  width: 150px;
}

.row-box >>> .van-field__control {
  width: 200px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box span {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.time-box {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  color: #333333;
  margin-top: 8px;
}

.time-box div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-box {
  position: fixed;
  bottom: 10px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  display: flex;
  justify-content: space-between;
  color: white;
}

.add_width {
  width: 172px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update_width {
  width: 115px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-cancel {
  background: #d2d3d4;
  border-radius: 8px 0px 0px 8px;
}

.btn-del {
  background: #f98c39;
}

.btn-confirm {
  background: #658CF1;
  border-radius: 0px 8px 8px 0px;
}

.action-box {
  padding: 20px 6px 20px 36px;
  display: flex;
  flex-wrap: wrap;
}

.action-item__box {
  width: 88px;
  height: 38px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 28px 0;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #333333;
}

.clickBk {
  background: #658CF1;
  color: #ffffff;
}
</style>